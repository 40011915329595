import relatorios from './relatorios'

export default [
  {
    icon: 'mdi-view-dashboard',
    title: 'Dashboard',
    to: '/consultoria/dashboard',
  },
  {
    icon: 'mdi-account-box',
    title: 'Consultor dashboard',
    to: 'dashboardConsultor',
  },
  {
    icon: 'mdi-factory',
    title: 'Agroindústrias',
    to: '/consultoria/agroindustrias',
  },
  {
    icon: 'mdi-cash',
    title: 'Cronograma Financeiro',
    group: '/consultoria',
    children: [
      {
        icon: 'mdi-account-hard-hat',
        title: 'Cronograma Financeiro',
        to: 'financeiro',
      },
      {
        icon: 'mdi-account-hard-hat',
        title: 'Cronograma por consultor',
        to: 'financeiros',
      },
    ],
  },
  {
    icon: 'mdi-account-multiple',
    title: 'Pessoas',
    group: '/consultoria',
    children: [
      {
        icon: 'mdi-account-hard-hat',
        title: 'Consultores',
        to: 'consultores',
      },
      {
        icon: 'mdi-home-flood',
        title: 'Fazendas',
        to: 'fazendas',
      },
      {
        icon: 'mdi-account',
        title: 'Prestadores',
        to: 'prestador',
      },
      {
        icon: 'mdi-account-cowboy-hat',
        title: 'Produtores',
        to: 'produtores',
      },
      {
        icon: 'mdi-account-cog',
        title: 'Supervisor',
        to: 'central',
      },
    ],
  },
  {
    icon: 'mdi-calendar-month',
    title: 'Agenda',
    to: '/consultoria/schedules',
  },
  {
    icon: 'mdi-google-analytics',
    title: 'Projetos',
    to: '/consultoria/projects',
  },
  {
    icon: 'mdi-clipboard-list',
    title: 'Relatórios',
    group: '/consultoria/relatorios',
    children: [
      {
        subicon: 'mdi-clipboard-list',
        title: 'Visitas',
        group: '/consultoria/relatorios',
        children: [
          ...relatorios,
          {
            icon: 'mdi-clipboard-multiple',
            title: 'Entregas de Material',
            to: 'entregas',
          },
        ],
      },
      {
        title: 'Gerenciais',
        group: '/consultoria/relatorios',
        children: [
          {
            icon: 'mdi-file-document-edit',
            title: 'Visitas por Consultor',
            to: 'visitas',
          },
          {
            icon: 'mdi-file-document-edit',
            title: 'Entrega de Material',
            to: 'entrega',
          },
          {
            icon: 'mdi-clipboard-list',
            title: 'Deslocamento de Consultores',
            to: 'deslocamento',
          },
          {
            icon: 'mdi-clipboard-list',
            title: 'Sincronização de Visitas',
            to: 'sinc',
          },
          {
            icon: 'mdi-home-search',
            title: 'Localização das Propriedades',
            to: 'localizacao',
          },
          {
            icon: 'mdi-home-map-marker',
            title: 'Distribuição de fazendas',
            to: 'farmDistribution',
          },
        ],
      },
    ],
  },
]
