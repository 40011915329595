import Vue from 'vue'
import admin from './admin'
import central from './central'
import consultor from './consultor'
import consultoria from './consultoria'
import gestor from './gestor'

export default () => {
  switch (Vue.prototype.$user.get().role) {
    case 'admin':
      return admin
    case 'consultor':
      return consultor
    case 'gestor':
      return gestor
    case 'central':
      return central
    case 'consultoria':
      return consultoria
    default:
      return []
  }
}
