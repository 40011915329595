<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    src="@/assets/sidebar-1.jpg"
    mobile-breakpoint="960"
    app
    width="300"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-row class="ma-3">
      <v-img :src="logo" max-height="43" contain />
    </v-row>
    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <!-- Politica de privacitade e termo de uso -->
    <template v-slot:append>
      <div class="d-flex flex-column align-center">
        <v-row>
          <div class="d-flex flex-column align-center mb-3 mr-4">
            <v-btn
              small
              icon
              color="primary"
              href="/terms/termos_de_uso.html"
              target="_blank"
            >
              <v-icon style="color: white; size: 24px">
                mdi-file-account-outline
              </v-icon>
            </v-btn>
            <span
              class="-text text-center"
              style="color: white; font-size: 10px"
            >
              <b>Termos de Uso</b>
            </span>
          </div>
          <div class="d-flex flex-column align-center mb-3">
            <v-btn
              small
              icon
              color="primary"
              href="/terms/politica_de_privacidade.html"
              target="_blank"
            >
              <v-icon style="color: white"> mdi-file-lock-outline </v-icon>
            </v-btn>
            <span
              class="-text text-center"
              style="color: white; font-size: 10px"
            >
              <b>Política de Privacidade</b>
            </span>
          </div>
        </v-row>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from 'vuex'
import { ENV_APP } from '../../../env'
import menu from '../../menus'

export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: menu(),
  }),

  computed: {
    ...mapState(['barColor', 'barImage']),
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    computedItems() {
      return this.items
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },
    logo() {
      return 'img/' + `${ENV_APP.imgFileMenu}`
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },
  },
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        margin-left: 0px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px

.v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon
  min-width: 0
</style>

<style scoped>
.link_menu {
  color: #4da3e6 !important;
  font-weight: 400;
  font-size: 16px;
}
</style>
