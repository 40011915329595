import relatorios from './relatorios'

export default [
  {
    icon: 'mdi-view-dashboard',
    title: 'Dashboard',
    to: '/central/dashboard',
  },
  {
    icon: 'mdi-home-flood',
    title: 'Fazendas',
    to: '/central/fazendas',
  },
  {
    icon: 'mdi-clipboard-list',
    title: 'Relatórios',
    group: '/central/relatorios',
    children: [...relatorios],
  },
]
