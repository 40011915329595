import relatorios from './relatorios'

export default [
  {
    icon: 'mdi-view-dashboard',
    title: 'Dashboard',
    to: '/consultor/dashboard',
  },
  {
    icon: 'mdi-home-flood',
    title: 'Fazendas',
    to: '/consultor/fazendas',
  },
  {
    icon: 'mdi-cash',
    title: 'Lançamento Financeiro',
    to: '/consultor/financeiros',
  },
  {
    icon: 'mdi-calendar-month',
    title: 'Agenda',
    to: '/consultor/schedules',
  },
  {
    icon: 'mdi-clipboard-list',
    title: 'Relatórios',
    group: '/consultor/relatorios',
    children: [...relatorios],
  },
]
