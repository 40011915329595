import store from '@/vuex/store'

let relatorios = [
  {
    id: 1,
    icon: 'mdi-clipboard-list',
    title: 'Visita Geral',
    to: 'geral',
  },
  {
    id: 2,
    icon: 'mdi-clipboard-text',
    title: 'Visita de CCS',
    to: 'ccs',
  },
  {
    id: 3,
    icon: 'mdi-clipboard-edit',
    title: 'Visita de CPP',
    to: 'cpp',
  },
  {
    id: 4,
    icon: 'mdi-clipboard-pulse',
    title: 'Visita Evolui Leite',
    to: 'evoluileite',
  },
  {
    id: 5,
    icon: 'mdi-clipboard-text-multiple',
    title: 'Visita de Boas Práticas',
    to: 'boaspraticas',
  },
  {
    id: 6,
    icon: 'mdi-clipboard-file',
    title: 'Visita de Resíduos de ATB',
    to: 'atb',
  },
  {
    id: 7,
    icon: 'mdi-clipboard-text-play',
    title: 'Visita de Fraudes',
    to: 'fraudes',
  },
  {
    id: 8,
    icon: 'mdi-clipboard-file',
    title: 'Visita Bonolat',
    to: 'bonolat',
  },
  {
    id: 9,
    icon: 'mdi-clipboard-multiple',
    title: 'Visita Educampo',
    to: 'educampo',
  },
  {
    id: 11,
    icon: 'mdi-clipboard-multiple',
    title: 'Visita Embriões',
    to: 'embrioes',
  },
  {
    id: 12,
    icon: 'mdi-clipboard-multiple',
    title: 'Visita Lactaleite',
    to: 'lactaleite',
  },
  {
    id: 13,
    icon: 'mdi-clipboard-pulse',
    title: 'Relatório Sólidos',
    to: 'solidos',
  },
  {
    id: 14,
    icon: 'mdi-clipboard-text-multiple',
    title: 'Relatório Promilat',
    to: 'promilat',
  },
]

store.watch(
  function (state) {
    if (state.user?.permissions.length > 0) {
      relatorios = relatorios.filter(item =>
        state.user.permissions.find(
          p => Number(p.report_id) === Number(item.id),
        ),
      )
    } else {
      const user = JSON.parse(localStorage.getItem('user'))

      if (user.permissions.length > 0) {
        relatorios = relatorios.filter(item =>
          user.permissions.find(p => Number(p.report_id) === Number(item.id)),
        )
      }
    }
  },
  {
    deep: true,
  },
)

export default relatorios
